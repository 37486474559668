<template lang="pug">
div.project_container
  div.view_container(v-bind:class="{view_container_small: isSmallScreen}")
    Header(v-on:is-small-screen="setIsSmallScreen")
  div.video_container
    PlayProject(
      v-if="slug"
      :slug="slug"
      ref="play_project"
      v-on:project_updated="updateProject"
    )
</template>

<script>
/* global gtag */

import Header from '../components/Header'
import PlayProject from './PlayProject'

export default {
  name: 'PlayProjectContainer',
  metaInfo() {
    return {
        title: `${this.recipientName}'s video`,
        meta: [
            { property: 'og:title', content: `${this.recipientName}'s video`},
            {property: 'og:video', content: `${this.projectVideoUrl}`},
            {name: 'robots', content: 'index,follow'}
        ]
    }
  },
  computed: {
    recipientName () {
      return this.project?.recipientName ? this.project?.recipientName : ""
    },
    projectVideoUrl () {
      return this.project?.videoUrls && this.project?.videoUrls.length > 0 ? this.project?.videoUrls[0] : ""
    },
  },
  props: {
    slug: String,
    showAsk: {
      type: Boolean,
      default: false
    },

  },
  components: {
    Header,
    PlayProject,
  },
  data () {
    return {
      project: null,
      isSmallScreen: false,
    }
  },
  methods: {
    updateProject: function(project) {
      this.project = project
      document.dispatchEvent(new Event('pre-render-trigger'))
    },
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    },
    stopPlayback: function() {
      this.$refs.play_project.stopProgressUpdate()
    },
  },
  beforeUnmount: function () {
    this.stopPlayback()
  },
  beforeDestroy: function () {
    document.removeEventListener("backbutton", this.yourCallBackFunction);
    this.stopPlayback()
  },
  beforeRouteUpdate (to, from, next) {
    // react to route changes...
    // don't forget to call next()
    this.stopPlayback()
    next()
  },
  mounted: function() {
    // window.vue = this

    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/play_project'})
    document.addEventListener("backbutton", this.stopPlayback, false);
  }
}
</script>

<style scoped>
.video_container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
</style>